import React from 'react'

function Button({ name, color, bgColor, hide, eventToParent }) {
  const handleClick = () => {
    eventToParent(true);
  }
  return (
    <input onClick={handleClick} type="button" value={name} className={`buttonStyle ${hide === 'true' ? `d-none d-sm-block` : ''}`} style={{backgroundColor : bgColor,color: color}} />
  )
}

export default Button