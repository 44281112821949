import React from 'react'
import Header from '../Shared Components/Header'
import NoDataScreen from '../Shared Components/NoDataScreen'
import HOME from '../Assets/home_img.png'

function Charity() {
    const VIDEO = ''
  return (
    <>
     <Header VIDEO={VIDEO} HOME={HOME} main_head={"Donate"} sub_head={""} showBtn={false}/>
     <NoDataScreen />
    </>
  )
}

export default Charity