import React from 'react'
import Header from '../Shared Components/Header'
import HOME from '../Assets/home_img.png'
import NoDataScreen from '../Shared Components/NoDataScreen';

function About() {
  const VIDEO = ''

  const scheduleData = [
    {
      day: 'Sunday',
      services: [
        { time: '7.00 am', service: 'Morning Prayer' },
        { time: '8.00 am', service: 'Holy Qurbana' }
      ]
    },
    {
      day: 'Saturday & Other Days',
      services: [
        { time: '6.30 am', service: 'Morning Prayer' },
        { time: '7.00 am', service: 'Holy Qurbana' }
      ]
    },
    {
      day: 'Wednesday',
      services: [
        { time: '5.30 pm', service: 'Evening Prayer' }
      ]
    }
  ];
  
  return (
    <>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"About"} sub_head={"Church"} showBtn={false}/>
      <div className="container">
        <h6 className='mt-5'>Service Timing</h6> 
        <hr />
        <table className="table table-bordered mb-5">
          <thead>
            <tr>
              <th>Day</th>
              <th>Time</th>
              <th>Service</th>
            </tr>
          </thead>
          <tbody>
            {scheduleData.map((item, index) => (
              <React.Fragment key={index}>
                {item.services.map((service, serviceIndex) => (
                  <tr key={serviceIndex}>
                    {serviceIndex === 0 && (
                      <td rowSpan={item.services.length}>{item.day}</td>
                    )}
                    <td>{service.time}</td>
                    <td>{service.service}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <h6 className='mt-5'>History</h6> 
        <hr />
        <NoDataScreen />
        <h6 className='mt-5'>Parish Administration</h6> 
        <hr />
        <NoDataScreen />
      </div>
    </>
  )
}

export default About