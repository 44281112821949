import React from 'react'
import HOME from '../Assets/home_img.png'
import Header from '../Shared Components/Header'

function News() {
    const VIDEO = ''
  return (
    <>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"News & Events"} sub_head={""} showBtn={false}/>
      <div className="container mb-5">
        <h6 className='mt-5'>Perunnal Related</h6> 
        <hr />
        <p><i className="fa-solid fa-location-pin"></i> 25/08/2024, കൊടിമരഘോഷയാത്ര</p>
      </div>
    </>
  )
}

export default News