import React, { useState } from 'react'
import Header from '../Shared Components/Header'
import HOME from '../Assets/home_img.png'
import Button from '../Shared Components/Button'
import Preview from '../Shared Components/Preview'
import imgSrc from '../Assets/notice_2024.png'

function EttuNomb() {
    const VIDEO = ''
    const date = new Date()

    const [toggle, setToggle] = useState(false)    

  function handleDataFromChild(data) {
    setToggle(data);
  }
  return (
    <>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={`Ettu Nomb ${date?.getFullYear()}`} sub_head={""} showBtn={false}/>
      <div className="container mt-3">
        <div className="head d-flex align-items-center justify-content-between">
          <h6>Notice 2024</h6>
          <Button name="View Notice" color="notNeeded" hide="false" eventToParent={handleDataFromChild}/>
        </div>
        <hr />
        <Preview toggle={toggle} onClose={() => setToggle(false)} header={`Notice ${date?.getFullYear()}`} imgSrc={imgSrc}/>
      </div>
    </>
  )
}

export default EttuNomb