import React from 'react'
import HOME from '../Assets/home_img.png'
import Header from '../Shared Components/Header'
import NoDataScreen from '../Shared Components/NoDataScreen'

function Live() {
    const VIDEO = ''
  return (
    <>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"Live Streams"} sub_head={""} showBtn={false}/>
      <NoDataScreen />
    </>
  )
}

export default Live