import React from 'react'
import Header from '../Shared Components/Header'
import NoDataScreen from '../Shared Components/NoDataScreen'
import HOME from '../Assets/home_img.png'

function SundaySchool() {
  const VIDEO = ''
  return (
    <>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"Organizations"} sub_head={"Sunday School"} showBtn={false}/>
      <NoDataScreen />
    </>
  )
}

export default SundaySchool