import React from 'react'
import Header from '../Shared Components/Header'
import VIDEO from '../Assets/home_video.mp4'
import HOME from '../Assets/home_img.png'

function Home() {
  
  return (
    <>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"St Mary's Orthodox Church"} sub_head={"(ആരാഴിപ്പളളി)"} showBtn={true}/>
    </>
  )
}

export default Home